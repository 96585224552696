import { render, staticRenderFns } from "./NavIcons.vue?vue&type=template&id=1f086c4d&scoped=true&"
var script = {}
import style0 from "./NavIcons.vue?vue&type=style&index=0&id=1f086c4d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f086c4d",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SvgBirds: require('C:/Projects/mpc-nuxt-master/v8/nuxt/components/SvgBirds.vue').default,SvgInsects: require('C:/Projects/mpc-nuxt-master/v8/nuxt/components/SvgInsects.vue').default,SvgRodents: require('C:/Projects/mpc-nuxt-master/v8/nuxt/components/SvgRodents.vue').default,SvgWasps: require('C:/Projects/mpc-nuxt-master/v8/nuxt/components/SvgWasps.vue').default})
